



















import mixins from 'vue-typed-mixins'
import DraftElement from '../../mixins/DraftElement'

export default mixins(DraftElement).extend({
  name: 'DefaultButton',
  props: {
    button: {
      type: Object,
      required: true
    },
    additionalClass: {
      type: String,
      default: null
    }
  }
})
